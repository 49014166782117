import React from "react"

// Components
import { StyledSvgPartying } from "./styled"

const PartyingImage = () => {
  return (
    <StyledSvgPartying
      id="ba1b68af-78e5-4a37-9190-4d69ed093763"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="894.13386"
      height="766.60871"
      viewBox="0 0 894.13386 766.60871"
    >
      <title>partying</title>
      <path
        d="M371.46582,566.515l-1.67773-1.08985a53.248,53.248,0,0,0-8.75977-68.31738c-.81006-.73926-1.646-1.457-2.48144-2.1748a40.41778,40.41778,0,0,1-6.60157-6.62891c-2.3706-3.25293-4.17285-8.30469-2.12353-12.72656l1.81445.8418c-1.68457,3.63378-.10986,7.91406,1.92529,10.707a38.33744,38.33744,0,0,0,6.28809,6.28906c.85107.73144,1.70215,1.46191,2.52734,2.21582a55.25022,55.25022,0,0,1,9.08887,70.88379Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#d0cde1"
      />
      <path
        d="M1011.86621,806.15855l-1.67773-1.08984a53.24791,53.24791,0,0,0-8.75879-68.31641c-.8125-.74121-1.64942-1.46-2.48633-2.17871a40.44667,40.44667,0,0,1-6.59766-6.625c-2.37011-3.25293-4.17285-8.30469-2.123-12.72754l1.81445.8418c-1.68457,3.63477-.11035,7.91406,1.92578,10.708a38.321,38.321,0,0,0,6.2832,6.28515c.85254.73243,1.70508,1.46485,2.53223,2.21973a55.247,55.247,0,0,1,9.08789,70.88281Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#d0cde1"
      />
      <path
        d="M643.085,748.7064a102.20146,102.20146,0,0,0-118.88623-57.80664c-2.02734.51954-4.0874,1.11426-6.0791,1.68848-5.55957,1.60547-11.30811,3.26465-17.22119,3.55762-7.38672.36035-16.96192-1.92969-21.65283-9.46778l1.69824-1.05664c4.2334,6.80274,13.03808,8.86719,19.856,8.52637,5.68018-.28125,11.31543-1.9082,16.76514-3.48047,2.00635-.5791,4.08057-1.17871,6.1377-1.70508a104.20028,104.20028,0,0,1,121.2124,58.9375Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#575a89"
      />
      <path
        d="M644.833,368.34019l-1.9668-.36328A102.19842,102.19842,0,0,0,577.10693,253.2982c-1.96826-.71142-4.00586-1.3789-5.97607-2.02441-5.499-1.80127-11.18506-3.66406-16.23779-6.74854-6.3169-3.856-12.937-11.1372-12.57373-20.00927l1.998.082c-.32763,8.00488,5.78662,14.66064,11.61768,18.22021,4.85449,2.96338,10.42822,4.78906,15.81836,6.55518,1.98437.6499,4.03662,1.32226,6.0332,2.04394A104.1997,104.1997,0,0,1,644.833,368.34019Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#575a89"
      />
      <path
        d="M394.58492,458.2376c-8.43414,15.55687-24.42146,22.54911-37.47123,17.1929-.18525-.07274-.37221-.152-.55313-.23274l-.20795.72089a.52946.52946,0,0,1-.02748.07963,2.7939,2.7939,0,0,1-4.27591,1.53509l-3.34559-2.19532a3.37633,3.37633,0,0,1-.85647-4.81064l.04846-.06044.56114-.717.12463-.1624c-9.8503-10.24561-11.5713-28.52677-3.33245-43.72343,9.34765-17.24184,27.9675-23.97171,41.5927-15.03108S403.93257,440.99576,394.58492,458.2376Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#d0cde1"
      />
      <ellipse
        cx="362.1054"
        cy="416.49918"
        rx="8.53843"
        ry="3.0914"
        transform="translate(-313.64482 173.0152) rotate(-30.31348)"
        fill="#fff"
        opacity="0.2"
      />
      <path
        d="M356.35261,475.91865a.52946.52946,0,0,1-.02748.07963l-8.42951-5.53131.56114-.717,8.65693,5.68053c-.18525-.07274-.37221-.152-.55313-.23274Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#575a89"
      />
      <ellipse
        cx="447.06693"
        cy="738.54732"
        rx="447.06693"
        ry="28.06139"
        fill="#d0cde1"
      />
      <polygon
        points="733.198 684.585 734.298 705.479 757.39 703.279 755.191 682.386 733.198 684.585"
        fill="#fbbebe"
      />
      <polygon
        points="644.126 660.393 645.226 681.286 668.318 679.087 666.119 658.194 644.126 660.393"
        fill="#fbbebe"
      />
      <path
        d="M902.07612,467.02019,818.50238,481.3157,813.00411,633.068,791.011,729.83762,826.2,745.23278l34.08929-95.66994,12.0962-84.6734,8.79724,84.6734,1.09965,106.66649,53.88307-3.299L923.28305,544.05207a194.32879,194.32879,0,0,0-21.20691-77.03184Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#2f2e41"
      />
      <path
        d="M889.4301,764.47674s-7.69759-8.79724-7.69759-5.49828-4.39861,35.18895-4.39861,35.18895,29.69067,8.79723,29.69067,14.29551,42.88652,14.2955,41.78687,3.299-38.48791-52.78342-38.48791-52.78342S894.92837,756.77915,889.4301,764.47674Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#2f2e41"
      />
      <path
        d="M801.69621,740.41724s-2.344-11.45206-3.97444-8.58415-21.215,28.41716-21.215,28.41716,21.46343,22.32149,18.74607,27.10134,30.21771,33.623,34.69646,23.51982-7.37234-64.90807-7.37234-64.90807S810.28036,736.44281,801.69621,740.41724Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#2f2e41"
      />
      <polygon
        points="647.077 279.102 707.957 284.923 728.25 229.622 669.968 215.583 647.077 279.102"
        fill="#2f2e41"
      />
      <circle cx="696.90946" cy="237.02585" r="30.79033" fill="#fbbebe" />
      <path
        d="M832.24806,322.41562s0,20.89344-16.49482,29.69067,39.58757,18.69413,39.58757,18.69413l5.49827-18.69413s-16.49482-7.69758-2.19931-25.29205Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#fbbebe"
      />
      <path
        d="M858.63977,346.608s-20.1399,12.47458-37.01149,1.28885l-6.97469-1.28885-20.89344,4.39862-9.89689,37.38825,37.38826,93.47064s64.87961,10.99654,78.07547-13.19586l-7.69759-98.9689L870.736,344.40871Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#575a89"
      />
      <polygon
        points="715.604 281.012 751.892 230.428 748.593 224.93 701.308 200.737 701.308 185.342 774.985 205.136 779.97 213.035 736.497 307.404 715.604 281.012"
        fill="#575a89"
      />
      <polygon
        points="643.026 285.411 640.827 284.311 585.844 282.112 627.631 245.823 614.435 229.328 551.755 276.613 551.755 287.61 640.827 324.998 643.026 285.411"
        fill="#575a89"
      />
      <path
        d="M766.81862,300.97236l7.69758-4.39862s16.49482-21.99309,23.09274-13.19585-15.39516,25.29205-15.39516,25.29205l-12.0962,4.39862Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#fbbebe"
      />
      <path
        d="M857.54011,255.3367l-12.09619-6.59793s-36.2886,3.299-31.89,10.99654,29.69068,1.09966,29.69068,1.09966l14.2955,4.39862Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#fbbebe"
      />
      <path
        d="M826.20533,274.004s-14.13391,4.71131-14.85161,23.31729a33.00142,33.00142,0,0,0,.98109,8.835c.79584,3.37286,1.30746,10.6302-4.8603,20.114A199.10676,199.10676,0,0,0,796.174,345.70872l-.04422.08843,15.52285,1.94035,3.30068-25.73024a128.12626,128.12626,0,0,1,10.1744-36.1216l.10742-.23956s6.79125,12.61232,20.37374,12.61232l-4.85089-4.85089s21.34392,8.7316,29.10535,8.7316c0,0,5.82106,3.88071,0,9.70178s-17.46321,19.40357-10.672,30.07553.97017,13.58249.97017,13.58249l10.672-5.82107,4.85089-2.91053V350.648l25.22464-2.91054s6.79124-.97017-7.76143-17.4632c0,0-3.84287-6.40479-2.16158-11.92883a27.86387,27.86387,0,0,0,1.05916-13.031C888.785,287.12683,875.99389,250.96746,826.20533,274.004Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#2f2e41"
      />
      <path
        d="M650.91783,777.42043c-.1201-.19624-2.95175-4.92435-3.93345-14.74237-.90045-9.00753-.32144-24.19041,7.5526-45.36929,14.91715-40.122-3.43774-72.49457-3.62529-72.817l.90556-.52535a80.35975,80.35975,0,0,1,7.58173,21.47834,93.70278,93.70278,0,0,1-3.88081,52.22892c-14.89159,40.05349-3.8205,59.01446-3.70705,59.2015Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#d0cde1"
      />
      <circle cx="492.15179" cy="568.6373" r="6.80291" fill="#d0cde1" />
      <circle cx="513.60713" cy="593.75575" r="6.80291" fill="#d0cde1" />
      <circle cx="498.9547" cy="610.50139" r="6.80291" fill="#d0cde1" />
      <circle cx="516.74694" cy="624.63051" r="6.80291" fill="#d0cde1" />
      <circle cx="493.72169" cy="646.60916" r="6.80291" fill="#d0cde1" />
      <path
        d="M655.02758,777.67084s-6.80292-16.74564,13.60583-29.30486Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#d0cde1"
      />
      <path
        d="M646.66105,777.36708s-3.09607-17.80759-27.05911-17.655Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#d0cde1"
      />
      <path
        d="M244.86146,592.3162l6,45s-19,56,1,110l7,39h26l3-13s-12-10-12-33l10-103,2-53Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#a0616a"
      />
      <path
        d="M267.86146,782.3162s-10-6-11,0-13,34-2,34,29-5,29-5,11-8,24-8,16-16,14-20-7-10-12-9a66.11969,66.11969,0,0,1-11,1l-15.78582-7.84769S280.86146,783.3162,267.86146,782.3162Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#2f2e41"
      />
      <path
        d="M308.66874,583.48937l25.90385,37.28258s8.69535,58.49265,51.16482,97.38152l24.051,31.48887,23.12548-11.88327-3.27331-12.93388s-15.24379-3.40983-25.75591-23.867l-38.18163-96.183-22.44471-48.0545Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#a0616a"
      />
      <path
        d="M415.96519,741.97114s-11.63671-.76616-9.78386,5.02754,3.97693,36.18265,13.76078,31.15511,23.50856-17.70163,23.50856-17.70163,6.12747-12.14307,17.69021-18.08471,6.91828-21.54384,3.3112-24.18751-10.79658-5.69508-14.78674-2.52039a66.11935,66.11935,0,0,1-9.32681,5.917l-17.62734.23483S427.985,736.91894,415.96519,741.97114Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#2f2e41"
      />
      <circle cx="122.42838" cy="227.12055" r="28" fill="#a0616a" />
      <polygon
        points="100.928 239.621 77.928 270.621 105.928 280.621 118.928 251.621 100.928 239.621"
        fill="#a0616a"
      />
      <path
        d="M294.86146,348.3162l-1.15167-1.06308a34.71,34.71,0,0,0-27.84832-8.93693h0s1.70079-13.50977-26.64961-12.25489L225.86146,337.3162s-18.5,18.5-13.5,46.5,5.5,98.5,5.5,98.5l15,123s113-5,134-51l-64-134Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#2f2e41"
      />
      <path
        d="M268.86146,378.3162l11,60,46,76s0,36,19,32-2-37-2-37l-41-81-8-50Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#a0616a"
      />
      <polygon
        points="112.928 286.621 114.928 320.621 145.923 317.576 140.928 283.621 112.928 286.621"
        fill="#2f2e41"
      />
      <path
        d="M304.80786,285.755s16.52067-31.78218-22.461-33.67186c0,0-23.2945-13.744-42.9444,14.49212l-20.3057,29.70561,4.84872-.71733.37344,4.81,5.94112,4.06114,4.84325-4.53478-1.12215,7.25273,51.68269,25.02543S266.84969,309.156,275.81,283.55037l2.80251,6.51489Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#2f2e41"
      />
      <path
        d="M579.516,609.41221c-11.536,43.73108-47.35727,73.05354-83.45932,70.64189-.51047-.0264-1.02924-.067-1.53379-.11581l-.07518,1.88148a1.3103,1.3103,0,0,1-.01963.21141,7.81009,7.81009,0,0,1-9.78845,7.04467l-9.76551-2.57608a7.81426,7.81426,0,0,1-5.13533-10.77692l.08423-.18233.96479-2.15219.21232-.48551c-31.09816-16.59088-46.76068-58.76042-35.49181-101.47892,12.78545-48.4676,55.38213-79.25182,95.153-68.76052S592.3014,560.94461,579.516,609.41221Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#006fbb"
      />
      <ellipse
        cx="472.04099"
        cy="535.76231"
        rx="22.59238"
        ry="7.56305"
        transform="translate(-393.68614 426.77504) rotate(-45.22238)"
        fill="#fff"
        opacity="0.2"
      />
      <path
        d="M494.44766,681.81977a1.3103,1.3103,0,0,1-.01963.21141L469.823,675.54052l.96479-2.15219,25.26887,6.66577c-.51047-.0264-1.02924-.067-1.53379-.11581Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#575a89"
      />
      <path
        d="M779.4386,166.48225c34.658,23.82144,48.45142,63.49879,34.18515,93.192-.19493.42233-.40514.84712-.61786,1.25721l1.63275.66945a1.25912,1.25912,0,0,1,.1797.08467,6.91326,6.91326,0,0,1,2.9162,10.53039l-5.55426,7.41233a7.57821,7.57821,0,0,1-11.224.86312l-.13235-.12982-1.57214-1.50747-.35645-.33557c-25.07874,20.903-67.50739,20.54-101.36286-2.72991-38.41177-26.40155-51.21484-72.26811-28.59468-102.45544S741.02682,140.08069,779.4386,166.48225Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#006fbb"
      />
      <ellipse
        cx="678.39197"
        cy="233.46914"
        rx="7.07916"
        ry="20.56631"
        transform="translate(-187.32113 248.43211) rotate(-25.52646)"
        fill="#fff"
        opacity="0.2"
      />
      <path
        d="M814.63864,261.60092a1.25912,1.25912,0,0,1,.1797.08467l-13.99443,18.676-1.57214-1.50747,14.372-19.17988c-.19493.42233-.40514.84712-.61786,1.25721Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#575a89"
      />
      <path
        d="M564.60908,114.79762c20.92079,36.42743,16.20057,78.12015-9.47649,98.698-.358.29613-.73086.58787-1.09962.86529l1.18121,1.30833a1.25789,1.25789,0,0,1,.12526.15389,6.90544,6.90544,0,0,1-1.92332,10.74357l-8.20464,4.27581a7.56965,7.56965,0,0,1-10.48122-4.072l-.0631-.17409-.76456-2.03686-.176-.45622c-31.61695,7.98995-69.67116-10.66829-90.10754-46.25225-23.18673-40.3729-14.90053-87.2117,18.5135-104.62528S541.42235,74.42472,564.60908,114.79762Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#006fbb"
      />
      <ellipse
        cx="444.66528"
        cy="131.46867"
        rx="20.54305"
        ry="7.07116"
        transform="translate(159.46996 509.20291) rotate(-89.89772)"
        fill="#fff"
        opacity="0.2"
      />
      <path
        d="M555.21418,215.66926a1.25789,1.25789,0,0,1,.12526.15389l-20.67228,10.77328-.76456-2.03686,21.23-11.06393c-.358.29613-.73086.58787-1.09962.86529Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#575a89"
      />
      <path
        d="M1037.02648,700.26239c-8.30433,15.31744-24.04559,22.20206-36.89451,16.92829-.1824-.07163-.36648-.14967-.54462-.22916l-.20474.70979a.52149.52149,0,0,1-.02706.07841,2.7509,2.7509,0,0,1-4.2101,1.51146l-3.2941-2.16153a3.32437,3.32437,0,0,1-.84329-4.7366l.04772-.05951.5525-.706.12271-.1599c-9.69869-10.08792-11.3932-28.08771-3.28115-43.05047,9.20377-16.97647,27.537-23.60276,40.95253-14.79973S1046.23026,683.28592,1037.02648,700.26239Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#d0cde1"
      />
      <ellipse
        cx="1005.04685"
        cy="659.16637"
        rx="8.40701"
        ry="3.04382"
        transform="matrix(0.86328, -0.50473, 0.50473, 0.86328, -348.22141, 530.70577)"
        fill="#fff"
        opacity="0.2"
      />
      <path
        d="M999.38261,717.67131a.52149.52149,0,0,1-.02706.07841l-8.29977-5.44618.5525-.706,8.52369,5.59311c-.1824-.07163-.36648-.14967-.54462-.22916Z"
        transform="translate(-152.93307 -66.69564)"
        fill="#575a89"
      />
    </StyledSvgPartying>
  )
}

export default PartyingImage
